html
{
  height:100%;
  width:100%;
}
body{    
  height:100%;
}
header{
  text-align: center;
  background-color: rgb(0, 0, 0);
}

.form-top{
  margin-top: 30px;
}
.contact-form {
  padding: 10px;
  border: 4px solid black;
  text-align:center;
}
.panel{
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.12), 0 1px 6px 0 rgba(0,0,0,.12);
  border-radius: 6px;
    border: 0;
}
.nav-item:hover{
  transform: scale(1.05);
  background-color: white;
  max-width: 200px;
}
.nav-item:active{
  transform: scale(1.1);
  background-color: white;
  max-width: 200px;
}
.navbar-nav{
  padding-left: 10px;
}
#navHead{
  float: left;
}
.title {
  text-align: center;
}
.postLabel {
  padding-top: 5px;
}
.selfIMG {
  padding-left: 10px;
  padding-bottom: 10px;
}
.blogBody {
  font-size: 18px;
  padding: 10px;
}
.blogTitle {
  padding-left: 10px;
}
.authorName {
  padding-left: 5px;
  padding-bottom: 10px;
  color:black
}
.links {
  padding: 30px;
}
#banner {
  min-height: 60px;
  border-bottom: 4px solid #5e2626;
  padding: 15px;
}
  #banner h1 {
    color: #5e2626;
    font-size: 38px;
    font-weight: bold;
    z-index: 2;
    text-align: center; }
    #banner h2 {
      color: white;
      font-size: 38px;
      font-weight: bold;}
  #banner h3 {
    color: #5e2626;
    font-size: 22px;
    font-weight: bold;
    z-index: 2;
    text-align: center; }
  #banner nav .navbar-nav {
    max-width: 455px;
    margin: 0 auto; 
    font-size: 20px;
  }
  #banner nav .navbar-nav .nav-link {
    color: white;
    font-weight: bold;
    border-bottom: 2px solid transparent; }
  #banner nav .navbar-nav .nav-link:hover {
    border-bottom: 2px solid #5e2626; 
    background-color: #810202;
  }
  #banner nav .navbar-nav .nav-link.active {
    color: #cc0000;
    text-shadow: 3px 3px 3px #fff;
    border-bottom: 2px solid #5e2626;
    background-color: white;
    }
@-moz-document url-prefix() {
    .form-control{
      height: auto;
    }
}
.panel-primary{
  background-color: #2c3e50;
  color: #fff;
}
.panel-primary>.panel-heading {
    color: #fff;
    font-size: 20px;
    background-color: #2c3e50;
    border-color: #2c3e50;
}
.btn-warning{
  background-color: transparent;
  border-color: #bdc3c7;
}
.card-title {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 10px;
  padding-top: 5px;
}
.card-body {
  text-align: center;
}
.card-columns {
  padding-left: 20px;
}
.blog-headers {
  padding-top: 10px;
  padding-bottom: 5px;
}
footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  border-top: 1px solid black;
  padding-bottom: 10px;
}
.footer-content{
  font-size: 10px;
  color: black;
}
#services {
  padding-bottom: 300px;
}
#products {
  padding-bottom: 300px;
}
#mission {
  padding-bottom: 300px;
}
#contact {
  padding-bottom: 50px;
}
.container {
  padding-bottom: 20px;
}